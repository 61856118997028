import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '@core/guards';
import { accessPageGuard } from '@features/security';
import {
  accountGuard,
  deactivateUserPageGuard,
  externalGuard,
  internalGuardMatch,
} from '@features/server-side-authentication';
import {
  ExternalAccessLayoutComponent,
  HomeComponent,
  InternalAccessLayoutComponent,
  MainComponent,
  Page403Component,
  Page404Component,
  PageReloadComponent,
  PageUnknownErrorComponent,
  UnAuthenticatedComponent,
} from './layout/components';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('@features/authentication/authentication.module').then((mod) => mod.AuthenticationModule),
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [accountGuard, authGuard],
    canDeactivate: [deactivateUserPageGuard],
    children: [
      {
        path: '',
        component: InternalAccessLayoutComponent,
        canMatch: [internalGuardMatch],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'organizations',
          },
          {
            path: 'organizations',
            loadChildren: () => import('@features/organizations').then((mod) => mod.OrganizationsModule),
          },
        ],
      },
      {
        path: '',
        component: ExternalAccessLayoutComponent,
        canActivate: [externalGuard],
        canDeactivate: [deactivateUserPageGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'home',
          },
          {
            path: 'user',
            loadChildren: () => import('@features/user/user.module').then((mod) => mod.UserModule),
          },
          {
            path: 'home',
            component: HomeComponent,
            pathMatch: 'full',
          },
          {
            path: 'sso',
            loadChildren: () =>
              import('@features/sso-provision/sso-provision.module').then((mod) => mod.SsoProvisionModule),
          },
          {
            path: 'data-sources',
            loadChildren: () =>
              import('@features/data-sources/data-sources.module').then((mod) => mod.DataSourcesModule),
          },
          {
            path: 'user-management',
            loadChildren: () => import('@features/user-management').then((mod) => mod.UserManagementModule),
            canActivate: [accessPageGuard],
            data: {
              permissions: ['view_user'],
            },
          },
          {
            path: 'role-management',
            loadChildren: () => import('@features/role-management').then((mod) => mod.RoleManagementModule),
            canActivate: [accessPageGuard],
            data: {
              permissions: ['view_role'],
            },
          },
          {
            path: 'categories',
            loadChildren: () => import('@features/categories').then((mod) => mod.CategoriesModule),
            canActivate: [accessPageGuard],
            data: {
              permissions: ['view_category'],
            },
          },
          {
            path: 'connectors',
            loadChildren: () => import('@features/connectors').then((mod) => mod.ConnectorsModule),
            canActivate: [accessPageGuard],
            data: {
              permissions: ['view_connector'],
            },
          },
          {
            path: 'audit-log',
            loadChildren: () => import('@features/audit-log').then((mod) => mod.AuditLogModule),
            canActivate: [accessPageGuard],
            data: {
              permissions: ['view_auditlog'],
            },
          },
          {
            path: 'notifications',
            loadChildren: () =>
              import('@features/notification-management').then((mod) => mod.NotificationManagementModule),
            canActivate: [accessPageGuard],
            data: {
              permissions: ['view_notifications'],
            },
          },
          {
            path: 'vpn-configuration',
            loadChildren: () => import('@features/vpn-configuration').then((mod) => mod.VpnConfigurationModule),
            canActivate: [accessPageGuard],
            data: {
              permissions: ['view_vpnconfig'],
            },
          },
        ],
      },
      {
        path: 'un-authenticated',
        component: UnAuthenticatedComponent,
      },
      {
        path: 'no-access',
        component: Page403Component,
      },
    ],
  },
  {
    path: 'reload',
    component: PageReloadComponent,
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/auth/login',
  },
  {
    path: 'unknown-error',
    component: PageUnknownErrorComponent,
  },
  {
    path: '**',
    component: Page404Component,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
